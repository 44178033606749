/* ==========================================================================
   Menu dynamique
   ========================================================================== */

.dropdown-base {
  padding: 0;
  border: none;
  
  ul.nav-base {
    margin: 0;
    
    li.list-group-item,
    li.list-group-item a {
      font-size: .9rem;
    }

    &:first-child .list-group-item:first-child,
    &:first-child .list-group-item:first-child a {
      border-radius: 0 !important;
    }

    &:last-child .list-group-item:last-child,
    &:last-child .list-group-item:last-child a {
      @include border-bottom-radius($list-group-border-radius);
    }
  }
}

ul.nav-base {
  @extend .list-group;

  li.list-group-item {
    padding: 0;

    >h4 {
      color: $brand-primary;
      margin-left: 15px;
      text-transform: uppercase;
    }

    &.active {
      border-color: $list-group-active-border;
    }

    &.active a,
    &.active a:hover,
    &.active a:active,
    &.active a:focus {
      color: $list-group-active-color;
      background-color: $list-group-active-bg;
    }

    a.active,
    a.active:hover,
    a.active:active,
    a.active:focus {

      &::after {
        transform: rotate(180deg);
      }
    }

    >a {
      display: block;
      padding: 10px 15px;
      text-decoration: none;
      color: $list-group-link-color;
      font-size: 1rem;
      white-space: nowrap;

      &:hover {
        color: $list-group-link-hover-color;
        background-color: $list-group-hover-bg;
      }

      &.openCat {
        padding-right: 30px;

        &::after {
          content: '\f0d7';
          position: absolute;
          top: 14px;
          right: 12px;
          font: normal normal normal 14px/1 FontAwesome;
          transition: all .3s;
        }
      }
    }
  }

  /*  Niv 2  */
  .sub1 {
    padding: 0;
    position: relative;

    .list-group-item {
      background-color: darken($list-group-bg, 3%);
      margin: 0;
      border-radius: 0;
      border: 0;
      border-top: solid 1px $list-group-border;

      a {
        font-size: .9rem;
      }
    }
  }

  /*  Niv 3  */
  .sub2 {
    @extend .sub1;

    .list-group-item {
      background-color: darken($list-group-bg, 5%);
    }

    a {
      font-size: .9rem;
    }
  }

  /*  Niv 4  */
  .sub3 {
    @extend .sub1;

    .list-group-item {
      background-color: darken($list-group-bg, 7%);
    }

    a {
      font-size: .8rem;
    }
  }

  /*  Niv 5  */
  .sub4 {
    @extend .sub1;

    .list-group-item {
      background-color: darken($list-group-bg, 9%);
    }

    a {
      font-size: .8rem;
    }
  }
}
