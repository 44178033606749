.tabstyle {
    width: 100%;
    background-color: white;
    color: #333;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: $line-height-computed;
    border: solid 1rem white;
}

.table-responsive:last-child .tabstyle {
    margin-bottom: 0;
}

.ecostyletitre {
    font-style: italic;
    padding: 1rem;
    line-height: 3.5rem;
}

.calqueeco {
    background-color: black; 
    vertical-align: middle; 
    text-align: center;
    font-style: italic;
    color: #eee;
    font-weight: 700;
    font-size: 1.7rem;
}

.tabfleche {
    position: relative;
    background: black;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 10px 14px 0;
        border-color: transparent black transparent transparent;
    }
}

.tabpfuite {
    background: url(../images/energie/point-de-fuite.png) repeat-x center;
}

.cellbordure {
    border-right: solid 1px #ddd; 
}