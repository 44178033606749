/* ==========================================================================
  Galeries
  ========================================================================== */

.nav-galerie,
.nav-galerie-col,
.btn-wrap {

  .btn {
    @extend .text-left;
    margin-bottom: 5px;
  }
}

.nav-galerie {
  @extend .text-center;
  margin-bottom: 2%;
}

.nav-galerie-col {

  .btn-group {
    @extend .clearfix;
    position: relative;
    display: block;

    .btn-link-dropdown {
      @extend .btn;
      @extend .btn-default;
      @extend .btn-block;
      text-align: left;
      width: calc( 100% - 25px);
    }

    .dropdown-toggle {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .list-group {
    margin-bottom: 5px;
  }

  .btn {
    white-space: normal;
  }
}

.galerie_thumbnail {
  @extend .thumbnail;

  img {
    width: 100%;
  }
}

.thumbnail-caption {
  @extend .text-center;
  font-size: $font-size-small;
  padding: 10px;
}

.grab {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

/* ==========================================================================
  Galerie-slide
  ========================================================================== */

.slick-caption {
  padding-top: 20px;
  text-align: center;
}

.slick-thumb {
  padding: 0 10px;
}

.slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ==========================================================================
  Galerie-carousel
  ========================================================================== */

.span-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: rgba(white, .6);
  text-align: center;
  font-size: 18px;
  color: $gray-darker;
}

/* ==========================================================================
  Galerie-masonry
  ========================================================================== */

.grid {
  overflow: hidden;
  margin: 0 -10px;
  padding-bottom: 10px;
}

.grid-item {
  width: 100%;
  padding: 10px;
  
  @media(min-width: $screen-sm-min) {
    width: 33.3333333%;
  }
  
  @media(min-width: $screen-md-min) {
    width: 25%;
  }
  
  >a {
    @extend .text-center;
    display: block;
    overflow: hidden;

    >img {
      @extend .img-responsive;
      display: inline-block;
      position: relative;
      z-index: 1;
      opacity: .9;
      transition: transform ease .3s, opacity ease .5s;
      box-shadow: $box-shadow;
    }

    &:hover>img {
      transform: scale(1.05);
      opacity: 1;
    }
  }

  .masonry-caption {
    display: block;
    padding: 20px;
    font-size: 15px;
    color: $gray-darker;
    background-color: white;
    position: relative;
    z-index: 2;
  }
}