.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

.slider-header {
	@extend .carousel-fade;
    margin: 0;
}

.slider-accueil {
    @extend .carousel-fade;
}

.slider-figure-charte {
    width: 100%;
    height: 35vh;
    min-height: 35rem;
    background-color: $body-bg;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(min-width: $screen-sm-min) {
        height: 50vh;
    }

    @media(min-width: $screen-md-min) {
        height: 70vh;
    }
}

.slider-figure-accueil {
    @extend .slider-figure-charte;
}


// Caption custom pour Slider Header :
// Modifier les valeurs les tailles des polices et positionnement en fonction de la taille du slider.

.slider-header__titre-1 {
    // @extend .animated;
    // @extend .fadeInLeft;
    line-height: 1;
    // font-size: $font-size-h3;
    @include font-size(3rem);

    color: white;
    // text-transform: uppercase;
    font-weight: 700;

    // @media(min-width: $screen-sm-min) {
    //     font-size: 4rem;
    // }

    // @media(min-width: $screen-lg-min) {
    //     // font-size: 6rem;
    //     font-size: 4rem;
    // }

    >span {
        display: inline-block;
        padding: .5% 0;
    }
}

.slider-header__titre-2 {
    // @extend .animated;
    // @extend .fadeInRight;
    animation-delay: .3s;
    line-height: 1;
    @include font-size(1.8rem);
    color: white;
    font-weight: 700;

    >span {
        display: inline-block;
        padding: .5% 0;
    }
}


.slider-header__carousel-caption {
    // @extend .text-center;
    position: absolute;
    left: 10%;
    bottom: 20%;
    right: 10%;

    @media(min-width: $screen-md-min) {
        left: 15%;
        bottom: 15%;
        right: 15%;
    }

    // titre blanc sur fond noir
    >.slider-header__titre-1-B {
        @extend .slider-header__titre-1;

        >span {
            background-color: rgba(black, .5);
            color: white;
        }
    }

    // titre noir sur fond blanc
    >.slider-header__titre-1-W {
        @extend .slider-header__titre-1;

        >span {
            background-color: rgba(white, .5);
            color: black;
        }
    }

    // titre blanc sur fond transparent
    >.slider-header__titre-1-T-W {
        @extend .slider-header__titre-1;

        >span {
            color: white;
        }
    }

    // titre noir sur fond transparent
    >.slider-header__titre-1-T-B {
        @extend .slider-header__titre-1;
        color: black;

        >span {
            color: black;
        }
    }

    // titre2 blanc sur fond noir
    >.slider-header__titre-2-B {
        @extend .slider-header__titre-2;

        >span {
            background-color: rgba(black, .5);
            color: white;
        }
    }

    // titre2 noir sur fond blanc
    >.slider-header__titre-2-W {
        @extend .slider-header__titre-2;

        >span {
            background-color: rgba(white, .5);
            color: black;
        }
    }

    // titre2 blanc sur fond transparent
    >.slider-header__titre-2-T-W {
        @extend .slider-header__titre-2;

        >span {
            color: white;
        }
    }

    // titre2 blanc sur fond transparent
    >.slider-header__titre-2-T-B {
        @extend .slider-header__titre-2;

        >span {
            color: black;
        }
    }

    // aspect du lien dynamique
    .slider-header__btn {
        @extend .btn;
        @extend .btn-sm;
        @extend .btn-warning;
        @extend .animated !optional;
        @extend .fadeIn !optional;
        animation-delay: .5s;
        margin-top: 2%;

        @media(min-width: $screen-sm-min) {
            // padding: 2% 3%;
            padding: 1rem 2rem;
            margin-top: 3%;
            text-transform: uppercase;
            font-size: $font-size-large;
            font-weight: 700;
        }
    }
}
