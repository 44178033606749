/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-basic {
	position: relative;

	.content-header {
		@extend .container-max;
		padding: 3rem;
	}

	.logo-header {

		>img {
			@extend .img-responsive;
			display: inline-block;
			width: 25rem;
		}
	}
}

@media only screen and (min-width: $grid-float-breakpoint) {
	.navbar{
		height: 100px;
		display:flex;
		align-items: center;
		justify-content: space-between;
	}

}
.navbar-brand {
  padding: 5px;
}
.navbar-brand>img {
  height: 100%;

  width: auto;
}
