/* ==========================================================================
	Global
	========================================================================== */

/* V - 10-12-2019 */

*::selection {
	background-color: #B3D4FB;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

	small{
		display: block;
		padding-top: 1rem;
	}
}

.page-header-top {
	margin: 50px 0;
	// position: relative;
	// padding-bottom: 3rem;

	//  &:after{
	// 	content : '';
	// 	position: absolute;
	// 	bottom: 0;
	// 	left:0;
	// 	width: 3rem;
	// 	height: 3px;
	// 	background-color: white;
	// }

	>h1,
	>.h1 {
		margin: 0;
	}
}

.btn, a, button {
	outline: 0 !important;
	transition: all 0.3s;
}

.thumbnail, .panel {
	border: none;
	box-shadow: $box-shadow;
}

.container-lg {
	@extend .container-fluid;
	max-width: 1400px;
}

.container-max {
	@extend .container-lg;

	@media(min-width: 1500px) {
		max-width: 85%;
	}
}

.container-main {
	@extend .container-max;

	@media(min-width: $screen-sm-min) {
		padding: 0 5% 6%;
	}
}

.no-pad {
	padding: 0;
}

.no-marge {
	margin: 0;
}

/* ==========================================================================
	Headers
	========================================================================== */

@import "header-basic";
// @import "header-basic-2";
// @import "header-slider-1";
// @import "header-slider-2";
// @import "header-slider-3";
// @import "header-slider-4";
// @import "header-slider-5";

/* ==========================================================================
	Navbar
	========================================================================== */

// .navbar-centered {

// 	@media (min-width: $grid-float-breakpoint) {
// 		.navbar-nav {
// 			display: inline-block;
// 			float: none;
// 			vertical-align: top;
// 		}

// 		.navbar-collapse {
// 			text-align: center;
// 		}
// 	}
// }

// .navbar {
	// @extend .navbar-centered; // pour centrer le contenu de la navbar.
// }

.navbar-nav {
	// @extend .navbar-right; // pour aligner à droite.

	>li {

		>a {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				right: 50%;
				height: 0;
				background-color: $brand-primary;
				opacity: 0;
				transition: all ease-in-out .3s;
			}
		}

		&:hover>a::after,
		&:active>a::after,
		&:focus>a::after,
		&.active>a::after {
			left: 1rem;
			right: 1rem;
			height: 3px;
			opacity: 1;
		}
	}
}

.index-nav, .index-red {
	@extend .pull-right;
	display: inline-block;
	line-height: $navbar-height;
	padding-right: 10px;
	color: $navbar-default-toggle-icon-bar-bg;
	text-transform: uppercase;
	font-weight: 700;

	&--text {
		color: red;
	}

	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}
}

/* ==========================================================================
	Main
	========================================================================== */

.main {
	@extend .clearfix;
	min-height: 50vh;
}

/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
	@extend .col-md-9;
	@extend .col-md-push-3;
	padding-bottom: 6rem;
}

/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
	@extend .col-md-3;
	@extend .col-md-pull-9;
	padding-top: 3rem;
}

/* ==========================================================================
	Footer
	========================================================================== */

.footer {
	background-color: black;
	color: white;
}

.container-footer {
	@extend .container-max;
	@extend .text-center;
	padding: 5%;

	@media (min-width: $screen-sm-min) {
		text-align: left;
	}
}

.footer-main {
	@extend .clearfix;
}

.footer-address {
	line-height: 1.8;
}

ul.list-footer {
	@extend .list-unstyled;
	margin: 0;

	>li {
		padding: 5px 0;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}
}

ul.list-footer-right {
	@extend .list-unstyled;
	margin: 0;

	>li {
		padding: 5px 0;
	}
}

ul.list-logo-footer {
	@extend .list-inline;
	margin-top: 30px;
	padding-top: 30px;
	border-top: solid 1px $hr-border;

	>li {
		padding: 0;
	}

	@media (max-width: $screen-sm-max) {
		text-align: center;
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px r$hr-border;
	}

	img {
		height: 37px;
	}
}

.panel-ref-footer {
	@extend .well;
}

/* ==========================================================================
	Custom
	========================================================================== */

.content-slider {
	margin: 30px auto;
	max-width: 1000px;
	box-shadow: $box-shadow;
}

.section-actu {
	@extend .container-fluid;
	padding-left: 5rem;
	padding-right: 5rem;

	@media(min-width: $screen-md-min) {
		padding-left: 5rem;
		padding-right: 5rem;
	}
}

.embed-responsive-map {
	padding-bottom: 40rem;
}

.bg-white {
	background-color: white;
}

.d-md-flex {

	@media(min-width: $screen-md-min) {
		display: flex;
	}
}

.p-relative {
	position: relative;
}

.block-address {

	@media(min-width: $screen-md-min) {
		position: absolute;
		top: 3rem;
		right: 3rem;
		width: 25%;
	}
}

// ******************
// MIXINS
// ******************

@mixin background(
  $imgpath: "",
  $overlay: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))
) {
  background: $overlay, url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

@mixin background2($imgpath: "", $color: rgba(0, 0, 0, 0), $opacity: 0) {
  background: linear-gradient(rgba($color, $opacity), rgba($color, $opacity)),
    url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

//********** FLEXI BOOTSTRAP FROM Brian Willis

@media only screen and (min-width: $screen-md-min) {
  .flex-row.row {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-row.row > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }
  .flex-row.row:after,
  .flex-row.row:before {
    display: flex;
  }
}

// ******************
// GRID
// ******************

// ******************
// COMPONENTS
// ******************

// -- Buttons

.btn-white {
  color: $gray-darker;
  background-color: $gray-lighter;
  border-color: $gray-lighter;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background-color: darken($gray-lighter, 10%);
    border-color: darken($gray-lighter, 10%);
  }
}

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary.btn-outline {
  color: $brand-primary;
}

.btn-success.btn-outline {
  color: $brand-success;
}

.btn-info.btn-outline {
  color: $brand-info;
}

.btn-warning.btn-outline {
  color: $brand-warning;
}

.btn-danger.btn-outline {
  color: $brand-danger;
}

.btn-white.btn-outline {
  color: $gray-lighter;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover,
.btn-white.btn-outline:hover {
  color: #fff;
}

//-- if two buttons are siblings, apply margin-left on the second
.btn {
  & + & {
    margin-left: 1rem;
  }
}

// ******************
// UTILS
// ******************

//-- btn-navbar //-- Add a btn style in nav to the last element

@media only screen and (min-width: $screen-sm-min) {
  .navbar-nav > li:last-child {
    //padding: 0.4rem 0;

    a {


      border: 2px solid $brand-primary;

      border-radius: $btn-border-radius-base;
      padding: $navbar-padding-horizontal 2rem;
      color: $brand-primary;

      &:hover {
        border: 2px solid darken($brand-primary, 10);
        background: darken($brand-primary, 10);
        color: white;
      }

      &::after {
        display: none;
      }
    }
  }
}

//-- Typography

.text-xs {
  @include font-size(0.75rem);
  line-height: 1rem;
}
.text-sm {
  @include font-size(0.875rem);
  line-height: 1.25rem;
}

.text-base {
  @include font-size(1rem);
  line-height: 1.5rem;
}
.text-lg {
  @include font-size(1.125rem);
  line-height: 1.75rem;
}
.text-xl {
  @include font-size(1.25rem);
  line-height: 1.75rem;
}
.text-2xl {
  @include font-size(1.5rem);
  line-height: 2rem;
}
.text-3xl {
  @include font-size(1.875rem);
  line-height: 2.25rem;
}
.text-4xl {
  @include font-size(2.25rem);
  line-height: 2.5rem;
}
.text-5xl {
  @include font-size(3rem);
  line-height: 1;
}
.text-6xl {
  @include font-size(3.75rem);
  line-height: 1;
}
.text-7xl {
  @include font-size(4.5rem);
  line-height: 1;
}
.text-8xl {
  @include font-size(6rem);
  line-height: 1;
}
.text-9xl {
  @include font-size(8rem);
  line-height: 1;
}

.font-light {
  font-weight: 200;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-white {
  color: white;
}

.text-lighter {
  color: $gray-lighter;
}

.text-light {
  color: $gray-light;
}

.text-gray {
  color: $gray;
}

.text-dark {
  color: $gray-dark;
}

.text-darker {
  color: $gray-darker;
}

span.underline {
  background-image: linear-gradient(
    90deg,
    lighten($brand-primary, 10%),
    lighten($brand-primary, 10%)
  );
  background-repeat: no-repeat;
  background-size: 100% 1rem;
  background-position: 0 115%;
}

//-- Display

.d-flex {
  display: flex;
}

@media only screen and (max-width: $screen-xs-min) {
  .d-xs-flex {
    display: flex;
  }
}
@media only screen and (min-width: $screen-sm-min) {
  .d-sm-flex {
    display: flex;
  }
}
@media only screen and (min-width: $screen-md-min) {
  .d-md-flex {
    display: flex;
  }
}
@media only screen and (min-width: $screen-lg-min) {
  .d-lg-flex {
    display: flex;
  }
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center--sm {
  display: inline;
}
@media only screen and (min-width: $screen-sm-min) {
  .flex-center--sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flex-row-align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  > img {
    height: 200px;
    margin: 10px;
  }
}

.flex-grid {
  display: flex;
}
.flex-grid .col {
  flex: 1;
}

@media (max-width: $screen-md-min) {
  .flex-grid {
    display: block;
    .col {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}

//-- Positions

.relative {
  position: relative;
}

//-- Spacing
.mt-0 {
  margin-top: 0;
}

.mt-1 {
  @include margin-top(1rem);
}

.mt-2 {
  @include margin-top(2rem);
}

.mt-3 {
  @include margin-top(3rem);
}

.mt-4 {
  @include margin-top(4rem);
}

.mt-5 {
  @include margin-top(5rem);
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  @include margin-bottom(1rem);
}

.mb-2 {
  @include margin-bottom(2rem);
}

.mb-3 {
  @include margin-bottom(3rem);
}

.mb-4 {
  @include margin-bottom(4rem);
}

.mb-5 {
  @include margin-bottom(5rem);
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-1 {
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}

.my-2 {
  @include margin-top(2rem);
  @include margin-bottom(2rem);
}

.my-3 {
  @include margin-top(3rem);
  @include margin-bottom(3rem);
}

.my-4 {
  @include margin-top(4rem);
  @include margin-bottom(4rem);
}

.my-5 {
  @include margin-top(5rem);
  @include margin-bottom(5rem);
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-1 {
  @include margin-left(1rem);
  @include margin-right(1rem);
}

.mx-2 {
  @include margin-left(2rem);
  @include margin-right(2rem);
}

.mx-3 {
  @include margin-left(3rem);
  @include margin-right(3rem);
}

.mx-4 {
  @include margin-left(4rem);
  @include margin-right(4rem);
}

.mx-5 {
  @include margin-left(5rem);
  @include margin-right(5rem);
}

.pt-1 {
  @include padding-top(1rem);
}

.pt-2 {
  @include padding-top(2rem);
}

.pt-3 {
  @include padding-top(3rem);
}

.pt-4 {
  @include padding-top(4rem);
}

.pt-5 {
  @include padding-top(5rem);
}

.pb-1 {
  @include padding-bottom(1rem);
}

.pb-2 {
  @include padding-bottom(2rem);
}

.pb-3 {
  @include padding-bottom(3rem);
}

.pb-4 {
  @include padding-bottom(4rem);
}

.pb-5 {
  @include padding-bottom(5rem);
}

.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.py-1 {
  @include padding-top(1rem);
  @include padding-bottom(1rem);
}

.py-2 {
  @include padding-top(2rem);
  @include padding-bottom(2rem);
}

.py-3 {
  @include padding-top(3rem);
  @include padding-bottom(3rem);
}

.py-4 {
  @include padding-top(4rem);
  @include padding-bottom(4rem);
}

.py-5 {
  @include padding-top(5rem);
  @include padding-bottom(5rem);
}

.py-8 {
  @include padding-top(8rem);
  @include padding-bottom(8rem);
}

.py-10 {
  @include padding-top(10rem);
  @include padding-bottom(10rem);
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  @include padding-left(1rem);
  @include padding-right(1rem);
}

.px-2 {
  @include padding-left(2rem);
  @include padding-right(2rem);
}

.px-3 {
  @include padding-left(3rem);
  @include padding-right(3rem);
}

.px-4 {
  @include padding-left(4rem);
  @include padding-right(4rem);
}

.px-5 {
  @include padding-left(5rem);
  @include padding-right(5rem);
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  @include margin-right(1rem);
}

.mr-2 {
  @include margin-right(2rem);
}

.mr-3 {
  @include margin-right(3rem);
}

.ml-1 {
  @include margin-left(1rem);
}

.ml-2 {
  @include margin-left(2rem);
}

.ml-3 {
  @include margin-left(3rem);
}

//-- Effects

.shadow-1 {
  box-shadow: 10px 10px 0 0 $brand-primary;
}

.shadow-2 {
  box-shadow: 1rem 2rem 4rem -2rem rgba(black, 0.9);
}

// see : https://codepen.io/iamvdo/pen/lGvCo
.drop-shadow {
  filter: drop-shadow(16px 16px 20px rgba(black, 0.3));
}

//-- Backgrounds

.bg-2a {
  @include background2("../images/assets/2a.jpg", #000, 0.3);
}
.bg-2b {
  @include background2("../images/assets/2b.jpg", #000, 0.3);
}

.bg-gradient-1 {
  @include background(
    "",
    linear-gradient(45deg, $brand-primary, $brand-success)
  );
}

.bg-marielouise {
  @include background2("https://picsum.photos/id/1018/1920/1280", #fff, 0);
  border: 3vw solid white;
}

.bg-white {
  background-color: white;
}

.bg-lighter {
  background-color: $gray-lighter;
}

.bg-light {
  background-color: $gray-light;
}

.bg-gray {
  background-color: $gray;
}

.bg-dark {
  background-color: $gray-dark;
}

.bg-darker {
  background-color: $gray-darker;
}

.bg-danger {
  background-color: $brand-danger !important;
}

.bg-success {
  background-color: $brand-success;
}

.bg-primary {
  background-color: $brand-primary;
}

.bg-primary-offset {
  position: relative;
  &:before {
    content: "";
    background-color: $brand-primary;
    position: absolute;
    display: block;
    width: 100%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

//-- Width utils

.w-100 {
  width: 100%;
}

.max-w-15 {
  max-width: 15rem;
}

//-- Height utils
.h-18 {
  height: 18rem;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

//-- Others

.object-cover {
  object-fit: cover;
}

/* image symetry */
.mirror {
  transform: scaleX(-1);
}

/*have space around image*/
.space-img {
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}
@media only screen and (min-width: $screen-md-min) {
  .space-img {
    margin: 0px;
  }
}
/* : to use for the top fixed nav => https://gist.github.com/nicoxxxcox/87b504a98efb6b0ca791b7a6d56319cd */
.affix {
  top: 0;
  right: 0;
  left: 0;
}


ul.list-icon {
  list-style: none;
  padding: 0;
}
ul.list-icon > li {
  padding-left: 1.3rem;
  padding-bottom: 0.3rem;
}
ul.list-icon > li:before {
  content: "\f058"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3rem; /* same as padding-left set on li */
  width: 1.3rem; /* same as padding-left set on li */
}


.blockquote-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 20px 10px;
}

.blockquote-3::before {
  content: open-quote;
}

.blockquote-3::after {
  content: close-quote;
}

.blockquote-3::before,
.blockquote-3::after {
  opacity: 0.25;
  padding: 0 10px;
  font-size: 5rem;
}



